<script>
import Filtro01 from './filtros/Filtro01.vue'
import Filtro02 from './filtros/Filtro02.vue'
import Filtro05 from './filtros/Filtro05.vue'
import Filtro06 from './filtros/Filtro06.vue'
import Relatorio from '@/components/shared/relatorio/Relatorio.vue'
import ListaRelatorios from '@/domain/ListaRelatorios.js'

export default {
  name: 'LayoutRelatorioConsignataria',

  components: {
    Filtro01,
    Filtro02,
    Filtro05,
    Filtro06,
  },

  extends: Relatorio,

  data() {
    return {
      relatorios: [],
    }
  },

  mounted() {
    this.carregarTipos()
  },

  methods: {
    carregarTipos() {
      this.listaRelatorios = new ListaRelatorios()
      this.listaRelatorios.relatoriosPorTipo.forEach((value, key) => {
        this.tipos.push(key)
      })

      this.relatorio.tipo = this.listaRelatorios.relatoriosPorTipo
        .keys()
        .next().value
      this.carregarNomesRelatoriosPorTipoSelecionado()
    },

    carregarNomesRelatoriosPorTipoSelecionado() {
      this.relatorios = this.listaRelatorios.relatoriosPorTipo.get(
        this.relatorio.tipo,
      )

      //Verificando permissões de visualização do relatório
      this.relatorios = this.relatorios
        .map((r) => {
          if (
            r.permissoes.some((permissao) =>
              this.$auth.roles.includes(permissao),
            )
          ) {
            return r // mantém o objeto atual no novo array
          }
          return null // descarta o objeto que não atende à condição
        })
        .filter((r) => r !== null)

      this.componente = ''
    },
  },
}
</script>
