<!-- eslint-disable vue/multi-word-component-names -->
<script>
import Filtro02 from '@/components/shared/relatorio/filtros/Filtro02.vue'

export default {
  extends: Filtro02,

  methods: {
    carregarListaEntidades() {
      this.entidadeService.getListaEntidades().then((res) => {
        this.entidades = res
      })
    },

    carregarConsignataria() {
      this.consignatariasSelecionadas.push(
        JSON.parse(localStorage.getItem('consignatariaSelecionada')),
      )
      this.adicionarConsignataria()
      this.exibirListaConsignataria = false
    },
  },
}
</script>
