<!-- eslint-disable vue/multi-word-component-names -->
<script>
import Filtro06 from '@/components/shared/relatorio/filtros/Filtro06.vue'

export default {
  extends: Filtro06,

  methods: {
    carregarListaEntidades() {
      this.entidadeService.getListaEntidades().then((res) => {
        this.entidades = res
      })
    },

    carregarConsignataria() {},
  },
}
</script>
