<!-- eslint-disable vue/multi-word-component-names -->
<script>
import Filtro01 from '@/components/shared/relatorio/filtros/Filtro01.vue'

export default {
  extends: Filtro01,

  methods: {
    carregarListaEntidades() {
      this.entidadeService.getListaEntidades().then((res) => {
        this.entidades = res
      })
    },

    carregarConsignataria() {
      this.consignatariasSelecionadas.push(
        JSON.parse(localStorage.getItem('consignatariaSelecionada')),
      )
      this.adicionarConsignataria()
      this.exibirListaConsignataria = false
    },
  },
}
</script>
